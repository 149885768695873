/** @jsxImportSource @emotion/react */
import { Carousel } from '@trendyol-js/react-carousel';
import Image from 'next/image';
import { useEffect } from 'react';

import { trackGA4InternalPromotionClick, trackGA4InternalPromotionImpression } from '@common/tracking';
import { buildSrcSet } from '@common/utlis';
import ArrowRightSvg from '@stories/atoms/Icons/arrow_right.svg';
import { breakpoints, media } from '@stories/theming/settings';
import imageLoader from '@utils/imageLoader';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { AdSlidesFragment } from '@gql/hygraph/graphql';
import type { ReactElement } from 'react';

const creativeName = 'ICP Ad Carousel';

export type IAdCarouselProps = {
  adSlides: AdSlidesFragment['adSlides'];
  ratio?: {
    mobile: number;
    tablet: number;
    desktop: number;
  };
};

export type TCategoryCardData = { name: string; imageSrc: string; id: number };
interface IArrowIcon {
  type: string;
  cssProps?: Interpolation<Theme>;
}
const ArrowIcon: React.FC<IArrowIcon> = ({ type, cssProps }) => {
  return (
    <div
      css={(t) => ({
        position: 'absolute',
        backgroundColor: t.color.grey.g92,
        color: t.color.primary,
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        zIndex: 10,
        height: '44px',
        width: '44px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          backgroundColor: t.color.hover.g92,
          color: t.color.hover.primary
        },
        ...(cssProps as object)
      })}
    >
      <ArrowRightSvg css={{ transform: type === 'left' ? 'rotate(180deg)' : 'unset' }}></ArrowRightSvg>
    </div>
  );
};

const AdCarousel: React.FC<IAdCarouselProps> = ({
  adSlides,
  ratio = { mobile: 2.5, tablet: 2.5, desktop: 6.4 }
}) => {
  // track GA4 promotion impression
  useEffect(() => {
    adSlides.forEach(({ trackingLabel }) => {
      trackGA4InternalPromotionImpression({
        creative_name: creativeName,
        promotion_name: trackingLabel
      });
    });
  }, [adSlides]);
  if (!adSlides?.length) return null;
  return (
    <Carousel
      show={1}
      slide={1}
      infinite
      rightArrow={
        <ArrowIcon
          type="right"
          cssProps={{
            right: 20
          }}
        ></ArrowIcon>
      }
      leftArrow={<ArrowIcon type="left" cssProps={{ left: 20 }}></ArrowIcon>}
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      {
        adSlides.map((element, index) => {
          const TagName = element.link?.href ? 'a' : 'div';
          return (
            <TagName
              key={element?.link?.href || element.caption}
              href={element?.link?.href || undefined}
              target={'_self'}
              onClick={() =>
                trackGA4InternalPromotionClick({
                  creative_name: creativeName,
                  creative_slot: `slide ${index}`,
                  promotion_name: element.trackingLabel
                })
              }
            >
              <div
                css={{
                  aspectRatio: ratio.mobile.toString(),
                  maxWidth: '100vw',
                  position: 'relative',
                  [media('tablet')]: { aspectRatio: ratio.tablet.toString() },
                  [media('desktopLarge')]: { aspectRatio: ratio.desktop.toString() }
                }}
              >
                <picture>
                  <source
                    srcSet={buildSrcSet(element.image, [1200, 1400, 1600, 1800, 1900])}
                    media={`(min-width: ${breakpoints.desktopLarge}px)`}
                  />
                  <source
                    srcSet={buildSrcSet(element.imageMedium, [700, 800, 900, 1000, 1200])}
                    media={`(min-width: ${breakpoints.tablet}px)`}
                  ></source>
                  <Image
                    src={element.imageSmall}
                    alt={element?.caption}
                    loader={imageLoader}
                    fill
                    sizes="100vw"
                  />
                </picture>
              </div>
            </TagName>
          );
        }) as ReactElement[]
      }
    </Carousel>
  );
};

export default AdCarousel;
